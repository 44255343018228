html, body {
  font-family: 'Roboto', sans-serif;
  text-align: justify;
  font-size: 16px;
  line-height: 1.4;
  height: 100%;
  margin: 0;
  padding: 0;
}

a {
  text-decoration: none;
  color: inherit;
}

#panel {

  padding-left: 0px;
  background-color: #e5ddd5;
  height: 95vh;
  overflow: auto;
}

#fond {

  background-image: url("img/bg.png");
  background-size: 366.5px 100%;
  position: fixed;
  min-height: 100%;
  width: 100%;
  opacity: 0.06;
  background-repeat: repeat repeat;
}

#form-container {

  position: fixed;
  bottom: 0;
  padding: 10px 15px;
  background-color: #F4F4F4;
  border-top: 1px solid black
}

#messages {

  position: relative;
  padding: 20px 10px 20px 15px;
  margin: 0;
  min-height: 92vh;
}

.message {

  clear: both;
  overflow: hidden;
  margin-bottom: 20px;
  transition: all 0.5s linear;
}

.message .avatar {

  width: 40px;
  height: 40px;
  border-radius: 50%;
  display: inline-block;
}

.message.my-message .avatar {

  float: right;

}

.message.other-message .avatar {

  float: left;
}

.message .message-container .text {

  text-align: center;
  margin-bottom: 10px;
}

.message .message-container .date {

  font-size: 1em;
  font-style: italic;
  font-weight: 100;
  color: dimgray;
}

.message .message-container {

  display: inline-block;
  padding: 6px 12px;
  border-radius: 1.3em;
  max-width: calc(95% - 85px);
  min-width: 100px;
  position: relative;
  font-size: 14px;
  font-weight: 300;
  line-height: 1.28;
  color: #262626;
  font-family: Open Sans sans-serif;
}

@media only screen and (max-width: 500px) {

  .message .message-container {

    max-width: calc(100% - 0px);
  }
}

.message.my-message .message-container {
  margin-right: 5px;
  float: right;
  background-color: #DDF7C8;
}

.message.other-message .message-container {
  background-color: #FFFFFF;
  margin-left: 5px;
}

#users {

  border-right: 1px solid black;
  height:100vh;
  background-color: #F4F4F4;
  overflow: auto;
  padding-top: 10px;
}

.img-user {

  margin: 0 auto;
  border-radius: 50%;
}

#online-users {

  color: red;
}

.total-member-hr {

  margin-top:10px;
  border-color: #0084ff;
}

.user-hr {
  border-color: #c5b11e;
}

.btn-file {
  position: relative;
  overflow: hidden;
}

.btn-file input[type=file] {
  position: absolute;
  top: 0;
  right: 0;
  min-width: 100%;
  min-height: 100%;
  font-size: 100px;
  text-align: right;
  filter: alpha(opacity=0);
  opacity: 0;
  outline: none;
  background: white;
  cursor: inherit;
  display: block;
}

.media {

  margin-bottom: 15px;
}

#gif-result {

  overflow-y: scroll;
  height: 600px;
}

.gif-result-img {

  cursor: pointer;
  height: 100px;
  width: 100%;
  margin-bottom: 1%;
}

// 404 Page

.not-found {
  position: absolute;
  width: 100%;
  top: 50%;
  transform: translateY(-50%);
  text-align: center;
  color: black;
  font-weight: bold;
  font-size: 2.5em;
}

.center {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  width: 100%;
  text-align: center;
}

.ant-skeleton-element {
  width: 100% !important;
}

.my-masonry-grid {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  width: auto;
}
.my-masonry-grid_column {
  background-clip: padding-box;
}

.my-masonry-grid_column > div {
  margin-bottom: 6px;
  margin-right: 6px;
}

